input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #06b975;
}

.ql-editor {
  height: 250px !important;
  max-height: 250px;
  overflow: auto;
}
