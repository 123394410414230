@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

*{
    font-family: 'Poppins', 'sans-serif';
}

.error-select .select__control{
    @apply border-red-600
}

.nav-none{
    display: none;
}